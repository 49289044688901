
import bonfire from "../assets/events/BonfireSocial.png";
import game from "../assets/events/GameNight.png";
import gbm1 from "../assets/events/GBM1.png";
import gbm2 from "../assets/events/GBM2.png";
import gbm3 from "../assets/events/GBM3.png";
import gbm4 from "../assets/events/GBM4.png";
import gbm5 from "../assets/events/GBM5.png";
import gbm6 from "../assets/events/GBM6.png";
import gbm7 from "../assets/events/GBM7.png";
import gbm8 from "../assets/events/GBM8.png";
import gbm9 from "../assets/events/GBM9.png";
import gbm10 from "../assets/events/GBM10.png";
import gbm11 from "../assets/events/GBM11.png";
import holiday from "../assets/events/HolidaySocial.png";
import reveal from "../assets/events/MentorReveal.png";
import mixer from "../assets/events/MocktailMixer.png";
import prog1 from "../assets/events/Programming1.png";
import prog2 from "../assets/events/Programming2.png";
import prog3 from "../assets/events/Programming3.png";
import prog4 from "../assets/events/Programming4.png";
import prog5 from "../assets/events/Programming5.png";
import downtown from "../assets/events/ProjectDowntown.png";
import pumpkin from "../assets/events/PumpkinSocial.png";
import repurpose from "../assets/events/RepurposeProject.png";
import scavenger2 from "../assets/events/ScavengerHunt2.png";
import scavenger1 from "../assets/events/ScavengerHunt.png";
import speeddating from "../assets/events/SpeedDating.png";
import study from "../assets/events/StudySocial.png";
import showcase from "../assets/events/SuccessShowcase.png";
import thanksgiving from "../assets/events/ThanksgivingPotluck.png";
import winterformal from "../assets/events/WinterFormal.png";

const events = [

  {
    name: "GBM #7",
    info: "Tue, Oct 29, 2024",
    desc:
      "",
    image: gbm7,
  },
  {
    name: "Game Night",
    info: "Fri, Nov 1, 2024",
    desc:
      "",
    image: game,
  },
  {
    name: "Project Downtown",
    info: "Sun, Nov 3, 2024",
    desc:
      "",
    image: downtown,
  },
  {
    name: "GBM #8",
    info: "Tue, Nov 5, 2024",
    desc:
      "",
    image: gbm8,
  },
  {
    name: "Study Social",
    info: "Thu, Nov 7, 2024",
    desc:
      "",
    image: study,
  },
  {
    name: "First-Gen Success Showcase",
    info: "Fri, Nov 8, 2024",
    desc:
      "",
    image: showcase,
  },
  {
    name: "Holiday Party!",
    info: "Sun, Nov 10, 2024",
    desc:
      "",
    image: winterformal,
  },
  {
    name: "GBM #9",
    info: "Tue, Nov 12, 2024",
    desc:
      "",
    image: gbm9,
  },
  {
    name: "Programming Event 4",
    info: "Wed, Nov 13, 2024",
    desc:
      "",
    image: prog4,
  },
  {
    name: "Study Social",
    info: "Wed, Nov 14, 2024",
    desc:
      "",
    image: study,
  },
  {
    name: "Thanksgiving Potluck",
    info: "Sat, Nov 16, 2024",
    desc:
      "",
    image: thanksgiving,
  },
  {
    name: "GBM #10",
    info: "Tue, Nov 19, 2024",
    desc:
      "",
    image: gbm10,
  },
  {
    name: "Programming Event 5",
    info: "Wed, Nov 20, 2024",
    desc:
      "",
    image: prog5,
  },
  {
    name: "Study Social",
    info: "Thu, Nov 21, 2024",
    desc:
      "",
    image: study,
  },
  {
    name: "GBM #11",
    info: "Tue, Dec 3, 2024",
    desc:
      "",
    image: gbm11,
  },
  {
    name: "Study Social",
    info: "Thu, Dec 5, 2024",
    desc:
      "",
    image: study,
  },
  {
    name: "Holiday Extravaganza",
    info: "Sat, Dec 7, 2024",
    desc:
      "",
    image: holiday,
  },
  {
    name: "Study Social",
    info: "Thu, Dec 12, 2024",
    desc:
      "",
    image: study,
  },

];

export default events;