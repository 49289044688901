import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Fade } from "react-awesome-reveal";
import logo from "../assets/gatorhead.png";
import { AlumniCard } from "../components/AlumniCard";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
// import data from "../data/cohort.js";
// import noImage from "../assets/better-logo.png";
import alum1 from "../assets/alumni/1A.jpeg";
import alum2 from "../assets/alumni/2A.jpeg";
import alum3 from "../assets/alumni/3A.jpeg";
import alum4 from "../assets/alumni/4A.jpeg";
import alum5 from "../assets/alumni/5A.jpeg";
import alum6 from "../assets/alumni/6A.jpeg";
import alum7 from "../assets/alumni/7A.jpeg";
import alum8 from "../assets/alumni/8A.jpeg";
import alum9 from "../assets/alumni/9A.jpeg";
import alum10 from "../assets/alumni/10A.jpeg";
import alum11 from "../assets/alumni/11A.jpeg";
import alum12 from "../assets/alumni/12A.jpeg";
import alum13 from "../assets/alumni/13A.jpeg";
import alum14 from "../assets/alumni/14A.jpeg";
import alum15 from "../assets/alumni/15A.jpeg";
import alum16 from "../assets/alumni/16A.jpeg";
import alum17 from "../assets/alumni/17A.jpeg";
import alum18 from "../assets/alumni/18A.jpeg";
import alum19 from "../assets/alumni/19A.jpg";
import alum20 from "../assets/alumni/20A.jpg";
import alum21 from "../assets/alumni/21A.jpg";
import alum22 from "../assets/alumni/22A.jpg";
import alum23 from "../assets/alumni/23A.jpg";
import alum24 from "../assets/alumni/24A.jpg";
import alum25 from "../assets/alumni/25A.jpg";
import alum26 from "../assets/alumni/26A.jpg";
import alum27 from "../assets/alumni/27A.jpg";
import alum28 from "../assets/alumni/28A.jpg";
import alum29 from "../assets/alumni/29A.jpg";
import alum30 from "../assets/alumni/30A.jpg";
import alum31 from "../assets/alumni/31A.jpg";

import "./Network.css";

const Network = () => {
  const [password, setPassword] = useState(""); // To store the entered password
  const [isAuthenticated, setIsAuthenticated] = useState(false); // Check if user is authenticated
  
  const correctPassword = "FGLP24"; // Set your actual password here

  // Handle password submission
  const handleSubmit = (e) => {
    e.preventDefault();
    if (password === correctPassword) {
      setIsAuthenticated(true); // Grant access if password matches
    } else {
      alert("Incorrect password, please try again."); // Show an error if password doesn't match
    }
  };

  return (
    <div>
      {isAuthenticated ? (
        // The protected content
        <div className="network-div">
          <div className="network-faux-title"></div>
          <div className="network-title-div">
            <div className="network-divider"></div>
            <Fade>
              <div className="network-title-container">
                <img className="network-image" src={logo} alt="" />
                <h1 className="network-title">Alumni Network</h1>
              </div>
            </Fade>
            <div className="network-divider"></div>
          </div>
          <div className="network-subtitle">Alumni</div>
          <div className="network-subtitle-div-background">
            <div className="network-subtitle-divider"></div>
          </div>
          <div className="network-photogrid">
            {/* Alumni Cards */}
          <AlumniCard 
          image={alum1}
          name="Yahir Peña"
          position="FYCS"
          
          linkedInUrl= "https://www.linkedin.com/in/yahir-pe%C3%B1a-673991232/"
          ></AlumniCard>
          <AlumniCard 
          image={alum2}
          name="Damian Rodriguez"
          position="Accounting"
          linkedInUrl = "https://www.linkedin.com/in/damian-rodriguez-b6a47b1b2/"
        ></AlumniCard>
        <AlumniCard
          image={alum3}
          name="Raymond Persaud"
          position="Business Management"
          linkedInUrl = "https://www.linkedin.com/in/raymond-persaud-97a1751b0/"
        ></AlumniCard>
        <AlumniCard
          image={alum4}
          name="Lessley Osorio Perez"
          position="Advertising"
          linkedInUrl = "https://www.linkedin.com/in/lessleyosorioperez/"
        ></AlumniCard>
        <AlumniCard
          image={alum5}
          name="Melissa Fraga"
          position="Nutritional Science"
          linkedInUrl = "https://www.linkedin.com/in/melissa-fraga/"
        ></AlumniCard>
        <AlumniCard
          image={alum6}
          name="Alexis Woodward"
          position="Nursing"
          linkedInUrl = "https://www.linkedin.com/in/alexis-woodward-b892b1254/"
        ></AlumniCard>
        <AlumniCard
          image={alum7}
          name="Danielle Petry"
          position="Biochemistry"
          linkedInUrl = "https://www.linkedin.com/in/danielle-petry-0369b0162/"
        ></AlumniCard>
        <AlumniCard
          image={alum8}
          name="Zully Torres"
          position="Psychlogy"
          linkedInUrl = "https://www.linkedin.com/in/zully-torres-5149b5206/"
        ></AlumniCard>
        <AlumniCard
          image={alum9}
          name="Danitsa Gonzalez"
          position="Animal Science"
          linkedInUrl = "https://www.linkedin.com/in/danitsa/"
        ></AlumniCard>
        <AlumniCard
          image={alum10}
          name="Maxim Dillon"
          position="Mechanical Engineering"
          linkedInUrl = "https://www.linkedin.com/in/maxim-dillon/"
        ></AlumniCard>
        <AlumniCard
          image={alum11}
          name="Isabella Nunez"
          position="Health Science"
          linkedInUrl = "https://www.linkedin.com/in/isabellanunez07/"
        ></AlumniCard>
        <AlumniCard
          image={alum12}
          name="Victoria Tieze"
          position="Health Sciences"
          linkedInUrl = "https://www.linkedin.com/in/victoria-tieze/"
        ></AlumniCard>
        <AlumniCard
          image={alum13}
          name="Abdurrahman Bejjaj"
          position="Geomatics"
          linkedInUrl = "https://www.linkedin.com/in/abdu-bejjaj?trk=public_post_feed-actor-name"
        ></AlumniCard>
        <AlumniCard
          image={alum14}
          name="Braden Beaney"
          position="Pharmacy School"
          linkedInUrl = "https://www.linkedin.com/in/braden-beaney-273431250/"
        ></AlumniCard>
        <AlumniCard
          image={alum15}
          name="Melissa Elmhorst"
          position="Education Sciences"
          linkedInUrl = "https://www.linkedin.com/in/melissaelmhorst/"
        ></AlumniCard>
        <AlumniCard
          image={alum16}
          name="Kashish Sachdeva"
          position="Pre-Dentistry"
          linkedInUrl = "https://www.linkedin.com/in/kashish-sachdeva-54809a254/"
          ></AlumniCard>
        <AlumniCard
          image={alum17}
          name="Alexis Zergott"
          position="Nursing"
          linkedInUrl = "https://www.linkedin.com/in/azergott/"
        ></AlumniCard>
        <AlumniCard
          image={alum18}
          name="Mauricio Del Castillo"
          position="Computer Science"
          linkedInUrl = "https://www.linkedin.com/in/mauriciodelcastillo/"
        ></AlumniCard>
        <AlumniCard
          image={alum19}
          name="Zy'Keria Gamble"
          position="FYCS"
          linkedInUrl = "https://www.linkedin.com/in/zykeriagamble/"
        ></AlumniCard>
        <AlumniCard
          image={alum20}
          name="Anthony Menendez"
          position="Microbiology"
          linkedInUrl = "https://www.linkedin.com/in/anthony-menendez/"
        ></AlumniCard>
        <AlumniCard
          image={alum21}
          name="Berlyne Baptiste"
          position="Health Science"
          linkedInUrl = "https://www.linkedin.com/in/berlyne-baptiste-02b682244/"
        ></AlumniCard>
        <AlumniCard
          image={alum22}
          name="Elizabeth Hernandez"
          position="Health Science"
          linkedInUrl = "https://www.linkedin.com/in/elizabethhernandez05/"
        ></AlumniCard>
        <AlumniCard
          image={alum23}
          name="Daniel Permane"
          position="Computer Science"
          linkedInUrl = "https://www.linkedin.com/in/danielpermane/"
        ></AlumniCard>
        <AlumniCard
          image={alum24}
          name="Jamsheed Gafur"
          position="" //MISSING MAJOR
          linkedInUrl = "https://www.linkedin.com/in/jamsheed-gafur-b8b54928a/"
        ></AlumniCard>
        <AlumniCard
          image={alum25}
          name="Johender Suarez"
          position="Business Administration"
          linkedInUrl = "https://www.linkedin.com/in/johender/"
        ></AlumniCard>
        <AlumniCard
          image={alum26}
          name="Keith Pecker"
          position="Exploratory"
          linkedInUrl = "https://www.linkedin.com/in/keith-pecker-4762b42a3/"
        ></AlumniCard>
        <AlumniCard
          image={alum27}
          name="Kevin Bencomo"
          position="Accounting"
          linkedInUrl = "https://www.linkedin.com/in/kevinbencomouf/"
        ></AlumniCard>
        <AlumniCard
          image={alum28}
          name="Mohammed Alrubaye"
          position="Biomedical Engineering"
          linkedInUrl = "https://www.linkedin.com/in/ma0101/"
        ></AlumniCard>
        <AlumniCard
          image={alum29}
          name="Natasha Counts"
          position="Health Science"
          linkedInUrl = "https://www.linkedin.com/in/natasha-counts/"
        ></AlumniCard>
        <AlumniCard
          image={alum30}
          name="Paris Brewton"
          position="" //MISSING MAJOR
          linkedInUrl = "https://www.linkedin.com/in/paris-brewton-1256312b1/"
        ></AlumniCard>
        <AlumniCard
          image={alum31}
          name="Yamariz Perez"
          position="Economics"
          linkedInUrl = "https://www.linkedin.com/in/yamarizperez/"
        ></AlumniCard>
        
         {/* <AlumniCard
          image={alum24}
          name="Emily Paraboschi"
          // position="major"
          linkedInUrl = "?"
        ></AlumniCard> */}
         {/* <AlumniCard
          image={alum18}
          name="Yazmine Nunez"
          // position="major"
          linkedInUrl = "?"
        ></AlumniCard> */}
        {/* <AlumniCard
          image={alum18}
          name="Julian Wences"
          // position="major"
          linkedInUrl = "https://www.linkedin.com/in/mauriciodelcastillo/"
        ></AlumniCard> */}
          </div>
        </div>
      ) : (
        // Password input form
        <div className="login-container">
          <form onSubmit={handleSubmit} className="login-form">
            <h1 className="login-title">Alumni Page Login</h1>
            <div className="login-field">
              <label htmlFor="password" className="login-label">
                Enter Password:
              </label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="login-input"
              />
            </div>
            <button type="submit" className="login-button">
              Submit
            </button>
          </form>
        </div>
      )}
    </div>
  );
};

export default Network;

