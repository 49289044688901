import React from "react";
import HomeSlider from "../components/HomeSlider";
import HomeAbout from "../components/HomeAbout";
import "./Home.css";

const Home = () => {
  return (
    <>
      <a
        href="https://docs.google.com/forms/d/e/1FAIpQLSelRyTBI6DCtqYluwqVay8A2Fy5d55VcXAnI-n2-t76ccSc4g/viewform?pli=1&pli=1"
        target="_blank"
        rel="noopener noreferrer"
      >
        {/* <div className="notification-banner">
          <p className="notification-text">
            FGLP Cohort 2023-24 applications are out now! All apps due September
            4th at 11:59pm!! {">"}
          </p>
        </div> */}
      </a>
      <HomeSlider></HomeSlider>
      <HomeAbout></HomeAbout>
    </>
  );
};

export default Home;
