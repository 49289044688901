import React, { useState, useEffect } from "react";
import "./About.css";
import { Fade } from "react-awesome-reveal";
import logo from "../assets/gatorhead.png";
import pic1 from "../assets/memberimage1.png";
import pic2 from "../assets/memberimage2.jpeg";
import pic3 from "../assets/memberimage3.jpg";

const About = () => {
  const [size, setSize] = useState(window.innerWidth);
  const [small, setSmall] = useState(false);
  const [smaller, setSmaller] = useState(false);

  const checkSize = () => {
    setSize(window.innerWidth);
  };

  useEffect(() => {
    if (size <= 1250) {
      setSmall(true);
    }
    if (size > 1250) {
      setSmall(false);
    }
    if (size <= 825) {
      setSmaller(true);
    }
    if (size > 825) {
      setSmaller(false);
    }

    window.addEventListener("resize", checkSize);
    return () => {
      window.removeEventListener("resize", checkSize);
    };
  }, [size]);

  return (
    <div className="about-div">
      <div className="about-faux-title"></div>
      <div className="about-title-div">
        <div className="about-divider"></div>
        <Fade triggerOnce>
          <div className="about-title-container">
            <img className="about-image" src={logo} alt="" />
            <h1 className="about-title">About</h1>
          </div>
        </Fade>
        <div className="about-divider"></div>
      </div>
      {/* <div className="about-notification">
        <a
          href="https://docs.google.com/forms/d/e/1FAIpQLSelRyTBI6DCtqYluwqVay8A2Fy5d55VcXAnI-n2-t76ccSc4g/viewform?pli=1&pli=1"
          target="_blank"
          rel="noopener noreferrer"
          className="about-notification-button"
        >
          Apply now!
        </a>
      </div> */}
      <Fade triggerOnce className="about-constitution-div">
        <h1 className="about-constitution-title-1">Our Purpose</h1>
        <div className="about-constitution-divider"></div>
        <p className="about-constitution-paragraph">
          The First Generation Leadership Program (FGLP) focuses on unleashing
          the skills within a select cohort of student leaders by equipping them
          with mentorship, networking opportunities, and access to real-world
          leadership. In an effort to develop the versatile leaders of tomorrow,
          we cultivate an environment rich in resilience and diplomacy. This
          will be accomplished through bi-weekly general body meetings, outreach
          and social events, and professional guest speakers.
        </p>
        <h1 className="about-constitution-title-1">What we offer</h1>
        <div className="about-constitution-divider"></div>
        <p className="about-constitution-paragraph">
          <span className="about-span-sub">Programming Events - </span> Many
          first-generation students lack the knowledge about resources available
          to them at university. We try to mitigate this by providing a wide
          range of information on different resourses on campus so that our
          members can accel with all the tools provided to them.
        </p>
        <p className="about-constitution-paragraph">
          <span className="about-span-sub">Mentorship - </span> Every member
          will choose their own mentor out of a pool of eager sophmores,
          juniors, or seniors, who will guide them through their journey at UF.
          Many being first-generation students themselves, they are equipped
          with the experience of navigating the Univeristy of Florida as an
          incoming first-generation student and are ready to share that
          knowledge to their mentees.
        </p>
        <p className="about-constitution-paragraph">
          <span className="about-span-sub">Social Activities - </span> We
          understand university can be stressful and overwhelming sometimes, we
          believe it is important to provide cohort members with experiences
          besides education. Socials are meant to give members, who might not
          have found their place yet, opportunities to mingle, de-stress, and
          have fun.
        </p>
      </Fade>
      <div className="about-member-section">
        <h1 className="about-member-section-title">Ask the members</h1>

        <div className="about-member-divider"></div>
        <div className="about-member-section-cards">
          <Fade triggerOnce>
            <div className="about-member-section-card-1">
              <div className="about-member-section-card-top">
                <img
                  src={pic1}
                  alt=""
                  className="about-member-section-card-image"
                ></img>
                <h1 className="about-member-section-card-header">
                  What makes FGLP different from<br></br> other student
                  organizations?
                </h1>
              </div>
              <div className="about-member-section-card-bot">
                <div className="about-member-section-card-bot-quote">
                  "When you're apart of FGLP you feel like you're actually apart
                  of something, I can't say this about alot of other
                  organizations at UF. FGLP is different."{" "}
                </div>
                <div className="about-member-section-card-bot-name-1">
                  - Aaron Chen{" "}
                </div>
              </div>
            </div>
            {small ? (
              <></>
            ) : (
              <div className="about-member-section-card-2">
                <div className="about-member-section-card-top">
                  <img
                    src={pic2}
                    alt=""
                    className="about-member-section-card-image"
                  ></img>
                  <h1 className="about-member-section-card-header">
                  How has FGLP impacted your life at UF?
                  </h1>
                </div>
                <div className="about-member-section-card-bot">
                  <div className="about-member-section-card-bot-quote">
                    {" "}
                    "FGLP was my first family at UF, offering a transformative 
                    experience that fostered both personal and professional growth. 
                    I am incredibly thankful for this wonderful organization, as it has
                     significantly shaped my journey and success at UF."
                  </div>
                  <div className="about-member-section-card-bot-name-2">
                    {" "}
                    - Kevin Bencomo
                  </div>
                </div>
              </div>
            )}
            {smaller ? (
              <></>
            ) : (
              <div className="about-member-section-card-3">
                <div className="about-member-section-card-top">
                  <img
                    src={pic3}
                    alt=""
                    className="about-member-section-card-image"
                  ></img>
                  <h1 className="about-member-section-card-header">
                    What does FGLP mean to you?
                  </h1>
                </div>
                <div className="about-member-section-card-bot">
                  <div className="about-member-section-card-bot-quote">
                    {" "}
                    "FGLP gave me the opportunity of meeting a diverse group of
                    students with amazing stories... It created a community for
                    me at UF, and it is the reason why I call UF home."
                  </div>
                  <div className="about-member-section-card-bot-name-3">
                    {" "}
                    - Isabella Ortiz
                  </div>
                </div>
              </div>
            )}
          </Fade>
        </div>
      </div>
      <div className="about-faq-div">
        <div className="about-faq-div-title">
          <div className="about-faq-title">Frequently Asked Questions</div>
          <div className="about-faq-divider"></div>
        </div>
        <div className="about-faq-questions">
          <div className="about-faq-question-1">
            <div className="about-faq-question">
              <div className="about-faq-question-header">
                What is first-generation?
              </div>
              <div className="about-faq-question-content">
                First-generation could be a couple things, first to go to
                college in your family, first generation raised in a new
                country, etc. however, we say first-gen means whatever it means
                to you.
              </div>
            </div>
            <div className="about-faq-question">
              <div className="about-faq-question-header">
                Is this only for freshman?
              </div>
              <div className="about-faq-question-content">
                FGLP is only for incoming freshman at the University of Florida,
                however, if you're a 2nd year or above you are always welcome to
                apply to become a mentor.
              </div>
            </div>
          </div>
          {smaller ? (
            <></>
          ) : (
            <div className="about-faq-question-2">
              <div className="about-faq-question">
                <div className="about-faq-question-header">
                  When do applications open?
                </div>
                <div className="about-faq-question-content">
                  Applications open the beginning of every new year, fall
                  semester. Applications for the 2024-25 school year are now closed.
                </div>
              </div>
              <div className="about-faq-question">
                <div className="about-faq-question-header">
                  Do mentors have to be first-gen?
                </div>
                <div className="about-faq-question-content">
                  No mentors do not need to be first-generation to have a
                  mentee, a good connection is the only thing needed there.
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default About;
