import React, { useState, useEffect } from "react";
import "./Footer.css";
import logo from "../assets/better-logo.png";
import { BsInstagram, BsFacebook } from "react-icons/bs";
import { Fade } from "react-awesome-reveal";
import { FaTiktok, FaLinkedin } from "react-icons/fa";
import { Link } from "react-router-dom";

const Footer = () => {
  const [size, setSize] = useState(window.innerWidth);
  const [small, setSmall] = useState(false);
  const [smaller, setSmaller] = useState(false);
  const [xSmaller, setXSmaller] = useState(false);

  const checkSize = () => {
    setSize(window.innerWidth);
  };

  useEffect(() => {
    if (size <= 980) {
      setSmall(true);
    }
    if (size > 980) {
      setSmall(false);
    }
    if (size <= 680) {
      setSmaller(true);
    }
    if (size > 680) {
      setSmaller(false);
    }
    if (size <= 540) {
      setXSmaller(true);
    }
    if (size > 540) {
      setXSmaller(false);
    }
    window.addEventListener("resize", checkSize);
    return () => {
      window.removeEventListener("resize", checkSize);
    };
  }, [size]);

  if (xSmaller) {
    return (
      <div className="footer-main-main-div">
        <div className="footer-main-div">
          <Fade triggerOnce cascade damping={0.1}>
            <Link className="footer-item-link" to="/">
              <img src={logo} alt="" className="logo" />
              <div className="footer-item-left-child">
                <p className="footer-item-left-child-title">
                  First-Generation <br></br>Leadership Program
                </p>
                <div className="horizontal-divider"></div>
              </div>
            </Link>
          </Fade>
        </div>
        <div className="bottom-text">Funded by SG @ 2024 </div>
      </div>
    );
  } else {
    return (
      <div className="footer-main-main-div">
        <div className="footer-main-div">
          <div className="footer-item-left">
            {smaller ? (
              <Fade triggerOnce cascade damping={0.1}>
                <Link className="footer-item-link" to="/">
                  <img src={logo} alt="" className="logo" />
                </Link>
              </Fade>
            ) : (
              <Fade triggerOnce cascade damping={0.1}>
                <Link className="footer-item-link" to="/">
                  <img src={logo} alt="" className="logo" />
                  <div className="footer-item-left-child">
                    <p className="footer-item-left-child-title">
                      First-Generation <br></br>Leadership Program
                    </p>
                    <div className="horizontal-divider"></div>
                  </div>
                </Link>
              </Fade>
            )}
          </div>
          <div className="footer-item-right">
            {small ? (
              <div className="footer-item-right-child">
                <Fade triggerOnce>
                  <p className="footer-item-content-title-socials">
                    Connect with us
                  </p>
                </Fade>
                <div className="footer-item-socials">
                  <Fade triggerOnce cascade damping={0.1}>
                    <a
                      href="https://www.instagram.com/fglpuf/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="social-link"
                    >
                      <BsInstagram size={50} className="socials"></BsInstagram>
                    </a>
                    <a
                      href="https://www.facebook.com/fglpuf"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="social-link"
                    >
                      <BsFacebook size={50} className="socials"></BsFacebook>
                    </a>
                    <a
                      href="https://www.tiktok.com/@fglpuf"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="social-link"
                    >
                      <FaTiktok size={50} className="socials"></FaTiktok>
                    </a>
                    <a
                      href="https://www.linkedin.com/in/uf-first-generation-leadership-program-3147b0217/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="social-link"
                    >
                      <FaLinkedin size={50} className="socials"></FaLinkedin>
                    </a>
                  </Fade>
                </div>
              </div>
            ) : (
              <>
                <div className="footer-item-right-child">
                  <Fade triggerOnce>
                    <p className="footer-item-content-title">Quick links</p>
                  </Fade>

                  <ul>
                    <Fade triggerOnce cascade damping={0.1}>
                      <Link to="/about">
                        <li className="footer-item-content-info">About</li>
                      </Link>
                      <Link to="/gallery">
                        <li className="footer-item-content-info">Gallery</li>
                      </Link>
                      <Link to="/members">
                        <li className="footer-item-content-info">Members</li>
                      </Link>
                      <Link to="/cohort">
                        <li className="footer-item-content-info">Cohort</li>
                      </Link>
                    </Fade>
                  </ul>
                </div>
                <div className="footer-item-right-child">
                  <Fade triggerOnce>
                    <p className="footer-item-content-title-socials">
                      Connect with us
                    </p>
                  </Fade>
                  <div className="footer-item-socials">
                    <Fade triggerOnce cascade damping={0.1}>
                      <a
                        href="https://www.instagram.com/fglpuf/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="social-link"
                      >
                        <BsInstagram
                          size={50}
                          className="socials"
                        ></BsInstagram>
                      </a>
                      <a
                        href="https://www.facebook.com/fglpuf"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="social-link"
                      >
                        <BsFacebook size={50} className="socials"></BsFacebook>
                      </a>
                      <a
                        href="https://www.tiktok.com/@fglpuf"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="social-link"
                      >
                        <FaTiktok size={50} className="socials"></FaTiktok>
                      </a>
                      <a
                        href="http://www.linkedin.com/in/uf-first-generation-leadership-program-3147b0217"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="social-link"
                      >
                        <FaLinkedin size={50} className="socials"></FaLinkedin>
                      </a>
                    </Fade>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="bottom-text">Funded by SG @ 2024 </div>
      </div>
    );
  }
};
export default Footer;
