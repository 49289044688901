import React from "react";
import ReactDOM from "react-dom";

import "./EventModal.css";
import { GrClose } from "react-icons/gr";
import { Fade } from "react-awesome-reveal";

export default function EventModal({ open, onClose, event }) {
  if (open) {
    document.body.style.overflow = 'hidden';
  } else if (!open) {
    document.body.style.overflow = 'unset';
    return null;
  }

  return (
    <>
      <div className="overlay" onClick={onClose} />
      <Fade style={{zIndex: "10000"}}>
      <div className="container">
        <div className="modal">
          <button onClick={onClose} className="close">
            <GrClose />
          </button>
          <div className="left">
            <h1 className="header">{event.name}</h1>
            <p className="date">{event.info}</p>
            <p className="location">{event.location ? event.location : 'Check GroupMe for location'}</p>
            {
              event.desc != ' ' ? (
                <div className="desc-container">
                  <h1>Description:</h1>
                  <div className="desc-divider"></div>
                  <p>{event.desc}</p>
                </div>
              ) : (<></>)
            }
          </div>
          <div className="right">
            <img src={event.image} width={"200px"} className="image" alt="" />
          </div>
        </div>
        <div className="event-divider"></div>
      </div>
      </Fade>
    </>
  );
};
