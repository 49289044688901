import React, { useState, useEffect } from "react";
import EventModal from "../components/EventModal"
import "../pages/Cohort.css";


export const EventCard = ({ event, onOpenModal }) => {
  const handleButtonClick = () => {
    onOpenModal(event);
  }
  return (
      <div className="cohort-upcoming-card">
        <img src={event.image} alt="" className="cohort-upcoming-image" />
        <div className="cohort-upcoming-card-div">
          <h1 className="cohort-upcoming-card-header">{event.name}</h1>
          <p className="cohort-upcoming-card-date">{event.info}</p>
          <p className="cohort-upcoming-card-loc">{event.location}</p>
          <button className="cohort-upcoming-card-button" onClick={handleButtonClick}>Info</button>
        </div>
      </div>
  );
};
