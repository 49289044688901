import React from "react";

export const AlumniCard = (props) => {
  return (
  
      <div className="network-photogrid-div">
        <img src={props.image} alt="" className="network-photogrid-image" />
        <h1 className="network-photogrid-name">{props.name}</h1>
        <p className="network-photogrid-p">{props.position}</p>
      {/* Using a standard HTML anchor tag */}
      {props.linkedInUrl && (
        <a
          href = {props.linkedInUrl}
          target="_blank"
          rel="noopener noreferrer"
          className="network-photogrid-link"
        >
          View LinkedIn Profile
        </a>
      )}
      </div>
    
  );
};

