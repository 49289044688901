import React, { useState, useEffect } from "react";
import { Fade, Slide } from "react-awesome-reveal";
import pic from "../assets/FGLP-gatorhead.png";
import "./HomeAbout.css";

const HomeAbout = () => {
  const [size, setSize] = useState(window.innerWidth);
  const [small, setSmall] = useState(false);

  const checkSize = () => {
    setSize(window.innerWidth);
  };

  useEffect(() => {
    if (size <= 620) {
      setSmall(true);
    }
    if (size > 620) {
      setSmall(false);
    }
    window.addEventListener("resize", checkSize);
    return () => {
      window.removeEventListener("resize", checkSize);
    };
  }, [size]);

  return (
    <div className="another-div">
      {small ? (
        <>
          <div className="content-div">
            <Slide triggerOnce direction="right">
              <div className="content-div-title">Our Purpose</div>
              <div className="content-div-divider"></div>
              <div className="content-div-p">
                The First Generation Leadership Program (FGLP) focuses on
                unleashing the skills within a select cohort of student leaders
                by equipping them with mentorship, networking opportunities, and
                access to real-world leadership. In an effort to develop the
                versatile leaders of tomorrow, we cultivate an environment rich
                in resilience and diplomacy. This will be accomplished through
                bi-weekly general body meetings, outreach and social events, and
                professional guest speakers.
              </div>
            </Slide>
          </div>
        </>
      ) : (
        <>
          <Slide triggerOnce direction="left">
            <Fade triggerOnce>
              <div className="content-div-1">
                <div className="content-div-title">University of Florida</div>
                <div className="content-div-p">
                  First-Generation Leadership Program
                </div>
                <img className="content-pic" src={pic} alt="FGLP" />
              </div>
            </Fade>
          </Slide>
          <Fade triggerOnce delay={250}>
            <div className="divider"></div>
          </Fade>
          <div className="content-div">
            <Slide triggerOnce direction="right">
              <div className="content-div-title">Our Purpose</div>
              <div className="content-div-divider"></div>
              <div className="content-div-p">
                The First Generation Leadership Program (FGLP) focuses on
                unleashing the skills within a select cohort of student leaders
                by equipping them with mentorship, networking opportunities, and
                access to real-world leadership. In an effort to develop the
                versatile leaders of tomorrow, we cultivate an environment rich
                in resilience and diplomacy. This will be accomplished through
                weekly general body meetings, outreach and social events, and
                professional guest speakers.
              </div>
            </Slide>
          </div>
        </>
      )}
    </div>
  );
};

export default HomeAbout;
