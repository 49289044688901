import React from "react";
import { Routes, Route } from "react-router-dom";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import Navbar from "./components/Navbar";
import About from "./pages/About";
import Members from "./pages/Members";
// import Resources from "./pages/Resources";
import Gallery from "./pages/Gallery";
import Cohort from "./pages/Cohort";
import NotFound from "./pages/404";
import Network from "./pages/Network";

const App = () => {
  return (
    <div>
      <Navbar></Navbar>
      <Routes>
        <Route path="*" element={<NotFound></NotFound>} />
        <Route path="/" element={<Home></Home>} />
        <Route path="/about" element={<About></About>} />
        <Route path="/gallery" element={<Gallery></Gallery>}></Route>
        {/* <Route path="/resources" element={<Resources></Resources>} /> */}
        <Route path="/members" element={<Members></Members>} />
        <Route path="/cohort" element={<Cohort></Cohort>} />
        <Route path="/network" element={<Network></Network>} />
      </Routes>
      <Footer></Footer>
    </div>
  );
};

export default App;
