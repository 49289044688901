import React from "react";
export const MemberCard = (props) => {
  // const [isOpen, setIsOpen] = useState(false);

  // const handleOnClick = () => {
  //   if (isOpen) {
  //     setIsOpen(false);
  //   } else {
  //     setIsOpen(true);
  //   }
  // };

  return (
    <div className="members-photogrid-div">
      <img src={props.image} alt="" className="members-photogrid-image" />
      <h1 className="members-photogrid-name">{props.name}</h1>
      <p className="members-photogrid-p">{props.position}</p>
    </div>
  );
};

export const CohortCard = (props) => {
  return (
    <>
      <div className="cohort-member-name-container">
        <img
          src={props.image}
          alt={props.name}
          className="cohort-member-picture"
        ></img>
        <div className="cohort-member-name-div-2">
          <p className="cohort-member-name">{props.name}</p>
        </div>
      </div>
    </>
  );
};
