import React, { useState } from "react";
import { Fade } from "react-awesome-reveal";
import logo from "../assets/gatorhead.png";
import { MemberCard } from "../components/MemberCard";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import data from "../data/cohort.js";
// import noImage from "../assets/better-logo.png";
import eboard1 from "../assets/eboard/1.jpg";
import eboard2 from "../assets/eboard/2.jpg";
import eboard3 from "../assets/eboard/3.jpg";
import eboard4 from "../assets/eboard/4.jpg";
import eboard5 from "../assets/eboard/5.jpeg";
import eboard6 from "../assets/eboard/6.jpg";
import eboard7 from "../assets/eboard/7.jpg";
import eboard8 from "../assets/eboard/8.jpg";
import eboard9 from "../assets/eboard/9.jpg";
import eboard10 from "../assets/eboard/10.jpg";
import eboard11 from "../assets/eboard/11.jpeg";
import eboard12 from "../assets/eboard/12.jpg";
import eboard13 from "../assets/eboard/13.jpeg";
import eboard14 from "../assets/eboard/14.jpg";
import eboard15 from "../assets/eboard/15.jpg";
import eboard16 from "../assets/eboard/16.jpg";
import eboard17 from "../assets/eboard/17.jpg";
import eboard18 from "../assets/eboard/18.jpg";
import eboard19 from "../assets/eboard/19.jpg";
import eboard20 from "../assets/eboard/20.jpg";
import eboard21 from "../assets/eboard/21.jpg";
import eboard22 from "../assets/eboard/22.jpeg";
// import aAbdu from "../assets/cohort/aAbdu.JPG";
// import aDookie from "../assets/cohort/aDookie.JPG";
// import aKhoja from "../assets/cohort/aKhoja.JPG";
// import aOrtecho from "../assets/cohort/aOrtecho.JPG";
// import aPolo from "../assets/cohort/aPolo.JPG";
// import aSa from "../assets/cohort/aSa.JPG";
// import aWoodward from "../assets/cohort/aWoodward.JPG";
// import aZergott from "../assets/cohort/aZergott.JPG";
// import bBeaney from "../assets/cohort/bBeaney.JPG";
// import bNguyen from "../assets/cohort/bNguyen.JPG";
// import cSmith from "../assets/cohort/cSmith.JPG";
// import dRodriguez from "../assets/cohort/dRodriguez.JPG";
// import eSaint_Lot from "../assets/cohort/eSaint-Lot.JPG";
// import eSocarras from "../assets/cohort/eSocarras.JPG";
// import gOcando from "../assets/cohort/gOcando.JPG";
// import iNunez from "../assets/cohort/iNunez.JPG";
// import iPerez from "../assets/cohort/iPerez.JPG";
// import iRodriguez from "../assets/cohort/iRodriguez.JPG";
// import jEubanks from "../assets/cohort/jEubanks.JPG";
// import jHostick from "../assets/cohort/jHostick.jpg";
// import jSipio from "../assets/cohort/jSipio.JPG";
// import jSyros from "../assets/cohort/jSyros.JPG";
// import jXie from "../assets/cohort/jXie.JPG";
// import jZuniga from "../assets/cohort/jZuniga.JPG";
// import kSachdeva from "../assets/cohort/kSachdeva.JPG";
// import kTyre from "../assets/cohort/kTyre.JPG";
// import kWilkes from "../assets/cohort/kWilkes.JPG";
// import lGonzales from "../assets/cohort/lGonzales.JPG";
// import lOsorio from "../assets/cohort/lOsorio.JPG";
// import mAragon from "../assets/cohort/mAragon.JPG";
// import mDillon from "../assets/cohort/mDillon.JPG";
// import mElmhorst from "../assets/cohort/mElmhorst.JPG";
// import mFraga from "../assets/cohort/mFraga.JPG";
// import mPascual from "../assets/cohort/mPascual.JPG";
// import mSkidmore from "../assets/cohort/mSkidmore.JPG";
// import mViel from "../assets/cohort/mViel.JPG";
// import nCavalcanti from "../assets/cohort/nCavalcanti.JPG";
// import pHarrypersad from "../assets/cohort/pHarrypersad.JPG";
// import rMichelle from "../assets/cohort/rMichelle.JPG";
// import sOdeh from "../assets/cohort/sOdeh.JPG";
// import sRavelo from "../assets/cohort/sRavelo.JPG";
// import sSegovia_Palacios from "../assets/cohort/sSegovia-Palacios.jpg";
// import sVillafane from "../assets/cohort/sVillafane.JPG";
// import tJohnson from "../assets/cohort/tJohnson.JPG";
// import vGimon from "../assets/cohort/vGimon.JPG";
// import vTieze from "../assets/cohort/vTieze.JPG";
// import ySerrano from "../assets/cohort/ySerrano.JPG";
import "./Members.css";

const Members = () => {
  const [isOpen1, setIsOpen1] = useState(true);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isOpen4, setIsOpen4] = useState(false);

  // const cohortImages = [
  //   aAbdu,
  //   noImage,
  //   aOrtecho,
  //   aWoodward,
  //   aZergott,
  //   aSa,
  //   aKhoja,
  //   noImage,
  //   aDookie,
  //   aPolo,
  //   bBeaney,
  //   bNguyen,
  //   cSmith,
  //   dRodriguez,
  //   noImage,
  //   eSocarras,
  //   eSaint_Lot,
  //   gOcando,
  //   iNunez,
  //   iRodriguez,
  //   iPerez,
  //   jSipio,
  //   jXie,
  //   jHostick,
  //   jZuniga,
  //   jEubanks,
  //   jSyros,
  //   kSachdeva,
  //   kWilkes,
  //   kTyre,
  //   lOsorio,
  //   lGonzales,
  //   mSkidmore,
  //   mViel,
  //   mDillon,
  //   mElmhorst,
  //   mFraga,
  //   mAragon,
  //   mPascual,
  //   nCavalcanti,
  //   noImage,
  //   pHarrypersad,
  //   rMichelle,
  //   sOdeh,
  //   sVillafane,
  //   sRavelo,
  //   sSegovia_Palacios,
  //   tJohnson,
  //   noImage,
  //   vTieze,
  //   vGimon,
  //   ySerrano,
  // ];

  const handleOnClick = (isOpen, num) => {
    if (num === 1) {
      if (isOpen) {
        setIsOpen1(false);
      } else {
        setIsOpen1(true);
      }
    } else if (num === 2) {
      if (isOpen) {
        setIsOpen2(false);
      } else {
        setIsOpen2(true);
      }
    } else if (num === 3) {
      if (isOpen) {
        setIsOpen3(false);
      } else {
        setIsOpen3(true);
      }
    } else if (num === 4) {
      if (isOpen) {
        setIsOpen4(false);
      } else {
        setIsOpen4(true);
      }
    }
  };

  return (
    <div className="members-div">
      <div className="members-faux-title"></div>
      <div className="members-title-div">
        <div className="members-divider"></div>
        <Fade>
          <div className="members-title-container">
            <img className="members-image" src={logo} alt="" />
            <h1 className="members-title">Members</h1>
          </div>
        </Fade>
        <div className="members-divider"></div>
      </div>
      <div className="members-subtitle">Executive Board</div>
      <div className="members-subtitle-div-background">
        <div className="members-subtitle-divider"></div>
      </div>
      <div className="members-photogrid">
        <MemberCard
          image={eboard1}
          name="Selin Ravelo"
          position="President"
        ></MemberCard>
        <MemberCard
          image={eboard2}
          name="Brandon Martin"
          position="Vice President"
        ></MemberCard>
         <MemberCard
          image={eboard21}
          name="Lauren Minor"
          position="Secretary"
        ></MemberCard>
        <MemberCard
          image={eboard3}
          name="Catherine Valdes"
          position="Treasurer"
        ></MemberCard>
        <MemberCard
          image={eboard4}
          name="Claudia Masmitja"
          position="Head of Public Relations"
        ></MemberCard>
        <MemberCard
          image={eboard5}
          name="Elias Sandoval"
          position="Chief of staff"
        ></MemberCard>
        <MemberCard
          image={eboard6}
          name="Katrina Mrozowski"
          position="Executive Advisor"
        ></MemberCard>
        <MemberCard
          image={eboard7}
          name="Christine Ngo"
          position="Service Director"
        ></MemberCard>
        <MemberCard
          image={eboard8}
          name="Yanet Serrano"
          position="GBM Director"
        ></MemberCard>
        <MemberCard
          image={eboard9}
          name="Aleyna Tunc"
          position="Special Events Director"
        ></MemberCard>
        <MemberCard
          image={eboard10}
          name="Brianna Alvarez"
          position="Special Events Director"
        ></MemberCard>
        <MemberCard
          image={eboard11}
          name="Laura Castellon"
          position="Mentorship Director"
        ></MemberCard>
        <MemberCard
          image={eboard12}
          name="Isabella Acevedo"
          position="Marketing Director"
        ></MemberCard>
        <MemberCard
        image={eboard13}
          name="Leslie Torres"
          position="Socials Director"
        ></MemberCard>
        <MemberCard
          image={eboard14}
          name="Yuridia Calihua"
          position="Socials Director"
        ></MemberCard>
        <MemberCard
          image={eboard15}
          name="Ashley Del Valle"
          position="Socials Director"
        ></MemberCard>
        <MemberCard
          image={eboard16}
          name="Isabella Del Chiaro"
          position="Socials Director"
          ></MemberCard>
        <MemberCard
          image={eboard17}
          name="Audrey Gabriel"
          position="Programming Director"
        ></MemberCard>
        <MemberCard
          image={eboard18}
          name="Edgar Perez"
          position="Programming Director"
        ></MemberCard>
        <MemberCard
          image={eboard19}
          name="Gabriela Torres"
          position="Programming Director"
        ></MemberCard>
        <MemberCard
          image={eboard20}
          name="Gonzalo Cabeza"
          position="Finance Director"
        ></MemberCard>
        <MemberCard
          image={eboard22}
          name="Monique Alfonso"
          position="Website Director"
        ></MemberCard>
      </div>
      {/* <div className="members-subtitle-div">
        <div className="members-subtitle">Cohort 2022 - 2023</div>
        <MdOutlineKeyboardArrowDown
          className="members-subtitle-button"
          size={40}
          onClick={() => {
            handleOnClick(isOpen1, 1);
          }}
        ></MdOutlineKeyboardArrowDown>
      </div>
      <div className="members-subtitle-div-background">
        <div className="members-subtitle-divider"></div>
      </div>
      <div className="cohort-members-photogrid">
        {isOpen1 ? (
          data.cohort22_23.map((name, index) => {
            return (
              <CohortCard name={name} image={cohortImages[index]}></CohortCard>
            );
          })
        ) : (
          <div></div>
        )}
      </div> */}
      <div className="members-subtitle-div">
        <div className="members-subtitle">Cohort 2024 - 2025</div>
        <MdOutlineKeyboardArrowDown
          className="members-subtitle-button"
          size={40}
          onClick={() => {
            handleOnClick(isOpen1, 1);
          }}
        ></MdOutlineKeyboardArrowDown>
      </div>
      <div className="members-subtitle-div-background">
        <div className="members-subtitle-divider"></div>
      </div>
      <div className="cohort-members-photogrid">
        {isOpen1 ? (
          data.cohort24_25.map((name) => {
            return (
              <div className="cohort-member-name-div">
                <p className="cohort-member-name">{name}</p>
              </div>
            );
          })
        ) : (
          <div></div>
        )}
      </div>

      <div className="members-subtitle-div">
        <div className="members-subtitle">Cohort 2023 - 2024</div>
        <MdOutlineKeyboardArrowDown
          className="members-subtitle-button"
          size={40}
          onClick={() => {
            handleOnClick(isOpen1, 1);
          }}
        ></MdOutlineKeyboardArrowDown>
      </div>
      <div className="members-subtitle-div-background">
        <div className="members-subtitle-divider"></div>
      </div>
      <div className="cohort-members-photogrid">
        {isOpen1 ? (
          data.cohort23_24.map((name) => {
            return (
              <div className="cohort-member-name-div">
                <p className="cohort-member-name">{name}</p>
              </div>
            );
          })
        ) : (
          <div></div>
        )}
      </div>
      <div className="members-subtitle-div">
        <div className="members-subtitle">Cohort 2022 - 2023</div>
        <MdOutlineKeyboardArrowDown
          className="members-subtitle-button"
          size={40}
          onClick={() => {
            handleOnClick(isOpen2, 2);
          }}
        ></MdOutlineKeyboardArrowDown>
      </div>
      <div className="members-subtitle-div-background">
        <div className="members-subtitle-divider"></div>
      </div>
      <div className="cohort-members-photogrid">
        {isOpen2 ? (
          data.cohort22_23.map((name) => {
            return (
              <div className="cohort-member-name-div">
                <p className="cohort-member-name">{name}</p>
              </div>
            );
          })
        ) : (
          <div></div>
        )}
      </div>
      <div className="members-subtitle-div">
        <div className="members-subtitle">Cohort 2021 - 2022</div>
        <MdOutlineKeyboardArrowDown
          className="members-subtitle-button"
          size={40}
          onClick={() => {
            handleOnClick(isOpen3, 3);
          }}
        ></MdOutlineKeyboardArrowDown>
      </div>
      <div className="members-subtitle-div-background">
        <div className="members-subtitle-divider"></div>
      </div>
      <div className="cohort-members-photogrid">
        {isOpen3 ? (
          data.cohort21_22.map((name) => {
            return (
              <div className="cohort-member-name-div">
                <p className="cohort-member-name">{name}</p>
              </div>
            );
          })
        ) : (
          <div></div>
        )}
      </div>
      <div className="members-subtitle-div">
        <div className="members-subtitle">Cohort 2020 - 2021</div>
        <MdOutlineKeyboardArrowDown
          className="members-subtitle-button"
          size={40}
          onClick={() => {
            handleOnClick(isOpen4, 4);
          }}
        ></MdOutlineKeyboardArrowDown>
      </div>
      <div className="members-subtitle-div-background">
        <div className="members-subtitle-divider"></div>
      </div>
      <div className="cohort-members-photogrid">
        {isOpen4 ? (
          data.cohort20_21.map((name) => {
            return (
              <div className="cohort-member-name-div">
                <p className="cohort-member-name">{name}</p>
              </div>
            );
          })
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
};

export default Members;
