import React, { useState, useEffect, useRef } from "react";
import { Fade } from "react-awesome-reveal";
import "./Navbar.css";
import logo from "../assets/better-logo.png";
import { CgMenu } from "react-icons/cg";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [size, setSize] = useState(window.innerWidth);
  const [small, setSmall] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [toggle, setToggle] = useState(false);
  const hamburgerDiv = useRef();
  const hamburgerButton = useRef();

  const checkSize = () => {
    setSize(window.innerWidth);
  };

  useEffect(() => {
    if (dropdown) {
      // add when mounted
      document.addEventListener("mousedown", handleClickOutside);
      // return function to be called when unmounted
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [small]);

  useEffect(() => {
    if (size <= 870) {
      setSmall(true);
    }
    if (size > 870) {
      setSmall(false);
    }
    if (size <= 625) {
      setDropdown(true);
    }
    if (size > 625) {
      setDropdown(false);
    }
    window.addEventListener("resize", checkSize);
    return () => {
      window.removeEventListener("resize", checkSize);
    };
  }, [size]);

  const handleClickOutside = (e) => {
    if (hamburgerDiv.current.contains(e.target)) {
      hamburgerDiv.current.style.visibility = "visible";
      return;
    }
    // outside click NOT INCLUDING BUTTON
    if (!hamburgerButton.current.contains(e.target)) {
      hamburgerDiv.current.style.visibility = "hidden";
    }
  };

  const handleClick = () => {
    if (toggle) {
      setToggle(false);
    } else {
      setToggle(true);
    }
    if (hamburgerDiv.current.style.visibility === "visible") {
      hamburgerDiv.current.style.visibility = "hidden";
    } else {
      hamburgerDiv.current.style.visibility = "visible";
    }
  };

  return (
    <div className="secondary-bar">
      <Fade triggerOnce>
        <Link to="/">
          {small ? (
            <div className="secondary-bar-logo">
              <img
                src={logo}
                alt="FGLP logo"
                className="secondary-bar-logo-image"
              />
            </div>
          ) : (
            <div className="secondary-bar-logo">
              <img
                src={logo}
                alt="FGLP logo"
                className="secondary-bar-logo-image"
              />
              <h1 className="secondary-bar-title">
                First-Generation Leadership Program
              </h1>
            </div>
          )}
        </Link>
      </Fade>
      <div className="secondary-bar-list">
        {dropdown ? (
          <div
            onClick={handleClick}
            ref={hamburgerButton}
            className="secondary-bar-list-item-4"
          >
            <CgMenu size={35}></CgMenu>
            <div ref={hamburgerDiv} className="hamburger-hidden">
              <Link className="hamburger-content-hidden-div" to="/about">
                <div className="hamburger-content-hidden">About</div>
              </Link>
              <Link className="hamburger-content-hidden-div" to="/gallery">
                <div className="hamburger-content-hidden">Gallery</div>
              </Link>
              <Link className="hamburger-content-hidden-div" to="/members">
                <div className="hamburger-content-hidden">Members</div>
              </Link>
              <Link className="hamburger-content-hidden-div" to="/cohort">
                <div className="hamburger-content-hidden">Cohort</div>
              </Link>
              <Link className="hamburger-content-hidden-div" to="/network">
                <div className="hamburger-content-hidden">Network</div>
              </Link>
            </div>
          </div>
        ) : (
          <Fade triggerOnce cascade damping={0.1}>
            <Link className="" to="/about">
              <div className="secondary-bar-list-item-2">About</div>
            </Link>
            <Link to="/gallery">
              <div className="secondary-bar-list-item-3">Gallery</div>
            </Link>
            <Link to="/members">
              <div className="secondary-bar-list-item-4">Members</div>
            </Link>
            <Link to="/cohort">
              <div className="secondary-bar-list-item-1">Cohort</div>
            </Link>
            <Link to="/network">
              <div className="secondary-bar-list-item-5">Network</div>
            </Link>
          </Fade>
        )}
      </div>
    </div>
  );
};

export default Navbar;
