import React, { useState, useEffect } from "react";
import "./404.css";
import { Fade } from "react-awesome-reveal";
import sadgeno from "../assets/sadgeno.png";

const NotFound = () => {
  const [size, setSize] = useState(window.innerWidth);
  const [small, setSmall] = useState(false);
  const [smaller, setSmaller] = useState(false);

  const checkSize = () => {
    setSize(window.innerWidth);
  };

  useEffect(() => {
    if (size <= 1250) {
      setSmall(true);
    }
    if (size > 1250) {
      setSmall(false);
    }
    if (size <= 825) {
      setSmaller(true);
    }
    if (size > 825) {
      setSmaller(false);
    }

    window.addEventListener("resize", checkSize);
    return () => {
      window.removeEventListener("resize", checkSize);
    };
  }, [size]);

  return (
    <Fade triggerOnce className="nf-container">
      <div className="nf-div">
        <img src={sadgeno} width="400px" className="sad-geno"></img>
        <div className="nf-info">
          <h1 className="nf-title">Uh oh...</h1>
          <div className="nf-divider"></div>
          <p className="nf-info-desc">Geno couldn’t find the right page for you and is sad. Maybe try selecting one above!</p>
          <p className="nf-info-note">Didn’t mean to come here? This is a <b>404 Error</b> - try contacting one of the Website Directors through the footer below.</p>
        </div>
      </div>
    </Fade>
  );
};

export default NotFound;
