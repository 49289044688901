const cohort2024_25 = [
  "Adam Becker", 
  "Adam Willis",
  "Alexa Ancheta",
  "Allison Phan",
  "Angelita Equite-Zarate",
  "Arely Serrano",
  "Ashley Rosas-Rios",
  "Balery Quinonez",
  "Benjamin Lewis", 
  "Bethany Spratt",
  "Bonnie Li",
  "Brenda Hernandez",
  "Brianna Bonilla", 
  "Claira Perez",
  "Diomary Orozco",
  "Elaine Buteau",
  "Estefania Diaz", 
  "Hillary Corrales",
  "Hyunwoo Kim",
  "Isabella Rojas",
  "Isabella Diaz", 
  "Jennifer Nicolas Lopez",
  "Jimmy Le",
  "Jordan Nguyen",
  "Joseangel Garcia",
  "Juan Yarala",
  "Justin Hovan",
  "Karma Deacon",
  "Kelvin Nguyen",
  "Ketlove Louica",
  "Khan Alam",
  "Kiara Hidalgo",
  "Kylie Spraggins",
  "Lissy Martinez",
  "Luisa Konageski Da Fonseca", 
  "Manel Cedeno Kampf",
  "Marcela Bados",
  "Matheus De Olivera De Moraes",
  "Melani Lamela",
  "Mia Ramirez",
  "Muhammed Enes Bilek", 
  "Mysha Khan",
  "Nathan Oliver", 
  "Nicole Marte",
  "Oscar Galicia-Merida", 
  "Pablo Barrios-Echevarria",
  "Patricio Flores", 
  "Rhianna Burton",
  "Ronald Lazo",
  "Sharon Sanju",
  "Simona Sartan",
  "Sofia Dyson",
  "Sophia Vigil",
  "Thinh Tran",
  "Yaxiel Nieves Natal", ]
const cohort2023_24 = [
  "Aleyna Tunch",
  "Anthony Menendez",
  "Ashley Del Valle",
  "Audrey Gabriel",
  "Berlyne Baptiste",
  "Brandon Martin",
  "Brenda Marquez-Solano",
  "Brianna Alvarez",
  "Catherine Valdes",
  "Christine Ngo",
  "Claudia Masmitja",
  "Daniel Permane",
  "Edgar Perez Vizcaino",
  "Elias Sandoval",
  "Elizabeth Hernandez",
  "Emily Paraboschi",
  "Fanny Chen",
  "Fatema Ashour",
  "Freddy Pardo",
  "Gabriela Torres",
  "Gonzalo Cabeza",
  "Isabella Acevedo",
  "Isabella Del Chiaro",
  "Jamsheed Gafur",
  "Johender Suarez",
  "Juan Lopez",
  "Julian Wences",
  "Katelyn Medina",
  "Keith Pecker",
  "Keshnee Alixe",
  "Kevin Bencomo",
  "Lara Salloum",
  "Laura Castellon",
  "Lauren Minor",
  "Leslie Torres",
  "Leticia Andrade",
  "Melissa Santiesteban",
  "Miguel Rodriguez",
  "Mohammed Alrubaye",
  "Monique Alfonso",
  "Natasha Counts",
  "Paris Brewton",
  "Sasha Hernandez-Rancel",
  "Shanna Chery",
  "Tanesha Pierre",
  "Yamariz Perez",
  "Yazmine Nunez",
  "Yona Ovdiyenko",
  "Yuridia Calihua-Panzo",
  "Zy'Keria Gamble",
];

const cohort2022_23 = [
  "Abdurrahman Bejjaj",
  "Alana Torres",
  "Aland Ortecho",
  "Alexis Woodward",
  "Alexis Zergott",
  "Alyssa Sa",
  "Amberina Khoja",
  "Anne Eugene",
  "Ariel Dookie",
  "Arnel Polo",
  "Braden Beaney",
  "Brandon Nguyen",
  "Cortney Smith",
  "Damian Rodriguez",
  "Danitsa Gonzales",
  "Elizabeth Menendez Socarras",
  "Elshamma Saint-Lot",
  "Grecia Ocando",
  "Isabella Nunez",
  "Isabella Rodriguez",
  "Isabella Perez",
  "Jacqueline Sipio",
  "Jay Xie",
  "Jayla Hostick",
  "Josue Zuniga",
  "Juliana Eubanks",
  "Julianna Syros",
  "Kashish Sachdeva",
  "Kennedy Wilkes",
  "Krista Tyre",
  "Lessley Osorio Perez",
  "Liliana Gonzalez-Alvanez",
  "Makayla Skidmore",
  "Marquis Viel",
  "Maxim Dillon",
  "Melissa Elmhorst",
  "Melissa Fraga",
  "Mia Aragon",
  "Miguel Pascual",
  "Nicolas Cavalcanti",
  "Nora Ripley",
  "Priyanka Harrypersad",
  "Rose Michelle Jeremie",
  "Saif Odeh",
  "Samantha Villafane",
  "Selin Ravelo",
  "Steffano Segovia-Palacios",
  "Tamirah Johnson",
  "Toshinari Minobe",
  "Victoria Tieze",
  "Viviana Gimon",
  "Yanet Serrano",
];

const cohort2021_22 = [
  "Aaron Chen",
  "Alexandra Quattrocki",
  "Ana Estrada",
  "Andrea Portillo-Sorto",
  "Andrew Bishop",
  "Andriana Detsis",
  "Ashley Henriquez",
  "Ashton Khan",
  "Brittany Barnes",
  "Diane Del Rio",
  "Daniela Martinez",
  "Emily Pecker",
  "Enya Doyle",
  "Florian Tacorian",
  "Hiba Karim",
  "Isabella Ortiz",
  "Javier Hurst",
  "Jenny Tran",
  "Jonathan Hernandez",
  "Julianna Miranda",
  "Karin Rashid",
  "Katrina Mrozowski",
  "Luna Pedrosa",
  "Mandy Tran",
  "Matthew Urra",
  "Mauricio Lara",
  "Mauricio Del Castillo",
  "Melanie Peña",
  "Raymond Persaud",
  "Salim Moutaouakkil",
  "Schayenne Hernandez",
  "Sophia Spata",
  "Shantzie Ponce",
  "Terrence Joseph",
  "Yahir Pena",
  "Zully Torres",
];

const cohort2020_21 = [
  "Andrew Jefferson Morales",
  "Shandia Lewis-Booker",
  "Lenna Menocal",
  "Serina Ferrer",
  "Carolina Katalina Sanchez",
  "Alexander J Munguia",
  "Reina Fortune",
  "Bailey McCracken",
  "Nathalie Are",
  "Sarah Gutierrez",
  "Quyen Nguyen",
  "Robert Fontan",
  "Adrian Fernandez",
  "Eklia St-Leger",
  "Isabella Ico",
  "Brianna Vo",
  "Ann Renee Saint Fleur",
  "Maria Kim Pham",
  "Jeniffer Maldonado ",
  "An Hoa Tran",
  "Britania Rowe",
  "Alyssa Singh",
  "Juan Garcia",
  "Precious Obiagwu ",
  "Shakira Bullock",
  "Mary Grant",
  "Kaitlyn Cabana",
  "Anne Thuy Le",
  "Juan Suarez ",
  "Keren Asghede",
];

const data = {
  cohort22_23: cohort2022_23,
  cohort21_22: cohort2021_22,
  cohort20_21: cohort2020_21,
  cohort23_24: cohort2023_24,
  cohort24_25: cohort2024_25,
};
export default data;
