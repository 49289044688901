import React, { useState, useEffect, useRef } from "react";
import logo from "../assets/gatorhead.png";
import "./Gallery.css";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { Fade } from "react-awesome-reveal";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { AiOutlineClose } from "react-icons/ai";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

const imageGallery = [];

const importImages = async () => {
  for (let i = 1; i < 14; i++) {
    const image = await import(`../assets/gallery_images/GBM/${i}-GBM.jpeg`);
    imageGallery.push(image.default);
  }

  for (let i = 1; i < 11; i++) {
    const image = await import(`../assets/gallery_images/INTRAMURALS/${i + 13}-INT.jpeg`);
    imageGallery.push(image.default);
  }

  for (let i = 1; i < 29; i++) {
    const image = await import(`../assets/gallery_images/SOCIALS/${i + 23}-SLS.jpeg`);
    imageGallery.push(image.default);
  }

  for (let i = 1; i < 18; i++) {
    const image = await import(`../assets/gallery_images/SPECIAL_E/${i + 51}-SPE.jpeg`);
    imageGallery.push(image.default);
  }
};

importImages();


const Gallery = () => {
  const [isClicked, setIsClicked] = useState(false);
  const [data, setData] = useState({ image: "", i: null });
  const imagefilter = useRef(null);

  const [isOpen1, setIsOpen1] = useState(true);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isOpen4, setIsOpen4] = useState(false); //ADDED

  const handleOnClick = (isOpen, num) => {
    if (num === 1) {
      if (isOpen) {
        setIsOpen1(false);
      } else {
        setIsOpen1(true);
      }
    } else if (num === 2) {
      if (isOpen) {
        setIsOpen2(false);
      } else {
        setIsOpen2(true);
      }
    } else if (num === 3) {
      if (isOpen) {
        setIsOpen3(false);
      } else {
        setIsOpen3(true);
      }
    } else if (num === 4) {
      if (isOpen) {
        setIsOpen4(false);
      } else {
        setIsOpen4(true);
      }
    }

  };

  const images = [];

for(let i = 1; i < 14; i++){
  images.push({image: imageGallery[i], desc: "General Body Meeting"});
}

for(let i = 1; i < 11; i++){
  images.push({image: imageGallery[i + 13], desc: "Intramurals"});
}

for(let i = 1; i < 29; i++){
  images.push({image: imageGallery[i + 23], desc: "Socials"});
}

for(let i = 1; i < 18; i++){
  images.push({image: imageGallery[i + 51], desc: "Special Events"});
}

  useEffect(() => {
    const handleKeyDown = (event) => {
      // Handle left arrow key
      if (event.key === "ArrowLeft") {
        setData((prev) =>
          prev.i === 0
            ? { image: images[prev.i].image, i: prev.i }
            : { image: images[prev.i - 1].image, i: prev.i - 1 }
        );
      }
      // Handle right arrow key
      else if (event.key === "ArrowRight") {
        setData((prev) =>
          prev.i === images.length - 1
            ? { image: images[prev.i].image, i: prev.i }
            : { image: images[prev.i + 1].image, i: prev.i + 1 }
        );
      } else {
        setData({ image: "", i: 0 });
      }
    };

    // Add event listener for keydown when the component mounts
    window.addEventListener("keydown", handleKeyDown);

    // Remove event listener when the component unmounts
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [data]);

  const handleFocusActions = (action) => {
    if (action === "back") {
      if (data.i === 0) {
        setData({ image: images[data.i].image, i: data.i });
      } else {
        setData({ image: images[data.i - 1].image, i: data.i - 1 });
      }
    } else if (action === "next") {
      if (data.i === images.length - 1) {
        setData({ image: images[data.i].image, i: data.i });
      } else {
        setData({ image: images[data.i + 1].image, i: data.i + 1 });
      }
    } else {
      setData({ image: "", i: 0 });
    }
  };

  const handleClickDescription = () => {
    if (isClicked) {
      setIsClicked(false);
    } else {
      setIsClicked(true);
    }
  };

  const handleViewImage = (image, i) => {
    setData({ image, i });
    console.log("Image i prop value: ", i);
  };

  return (
    <>
      {data.image && (
        <div className="gallery-focus">
          <button
            onClick={() => handleFocusActions()}
            className="gallery-focus-close"
          >
            <AiOutlineClose color="white" size={40} />
          </button>
          <button
            onClick={() => handleFocusActions("back")}
            className="gallery-focus-next"
          >
            <IoIosArrowBack
              color="white"
              className="gallery-focus-icon"
              size={60}
            />
          </button>

          <div className="gallery-focus-image-container">
            {isClicked ? (
              <>
                <img
                  src={data.image}
                  ref={imagefilter}
                  alt={images[data.i].desc}
                  className="gallery-focus-image-filtered"
                  onClick={handleClickDescription}
                />
                <p className="gallery-focus-desc">{images[data.i].desc}</p>
              </>
            ) : (
              <>
                <img
                  src={data.image}
                  ref={imagefilter}
                  alt={images[data.i].desc}
                  className="gallery-focus-image"
                  onClick={handleClickDescription}
                />
              </>
            )}
          </div>

          <button
            onClick={() => handleFocusActions("next")}
            className="gallery-focus-next"
          >
            <IoIosArrowForward
              color="white"
              className="gallery-focus-icon"
              size={60}
            />
          </button>
        </div>
      )}
      <div className="gallery-div">
        <div className="gallery-faux-title"></div>
        <div className="gallery-title-div">
          <div className="gallery-divider"></div>
          <Fade triggerOnce>
            <div className="gallery-title-container">
              <img className="gallery-image" src={logo} alt="" />
              <h1 className="gallery-title">Gallery</h1>
            </div>
          </Fade>
          <div className="gallery-divider"></div>
        </div>

        <div className="gallery-images">
          <div className="gallery-subtitle-div">
            <h1 className="gallery-subtitle">General Body Meetings</h1>
            <MdOutlineKeyboardArrowDown
              className="gallery-subtitle-button"
              size={40}
              onClick={() => {
                handleOnClick(isOpen1, 1);
              }}
            ></MdOutlineKeyboardArrowDown>
            <div></div>
          </div>
          <div className="gallery-subtitle-divider"></div>
          {isOpen1 ? (
            <ResponsiveMasonry
              columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 4 }}
            >
              <Masonry columnsCount={4} gutter="10px">
                {images.slice(0, 12).map((element, i) => (
                  <div className="gallery-lazy-load-box">
                    <Fade key={i} triggerOnce cascade={0.3}>
                      <img
                        key={i}
                        alt={element.desc}
                        src={element.image}
                        className="gallery-images-child"
                        onClick={() => handleViewImage(element.image, i)}
                      />
                    </Fade>
                  </div>
                ))}
              </Masonry>
            </ResponsiveMasonry>
          ) : (
            <div></div>
          )}
          <div className="gallery-subtitle-div">
            <h1 className="gallery-subtitle">Intramurals </h1>
            <MdOutlineKeyboardArrowDown
              className="gallery-subtitle-button"
              size={40}
              onClick={() => {
                handleOnClick(isOpen2, 2);
              }}
            ></MdOutlineKeyboardArrowDown>
            <div></div>
          </div>
          <div className="gallery-subtitle-divider"></div>
          {isOpen2 ? (
            <ResponsiveMasonry
              columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 4 }}
            >
              <Masonry columnsCount={4} gutter="10px">
                {images.slice(12, 22).map((element, i) => (
                  <div className="gallery-lazy-load-box">
                    <Fade key={i} triggerOnce cascade={0.3}>
                      <img
                        key={i}
                        alt={element.desc}
                        src={element.image}
                        className="gallery-images-child"
                        onClick={() => handleViewImage(element.image, i + 14)}
                      />
                    </Fade>
                  </div>
                ))}
              </Masonry>
            </ResponsiveMasonry>
          ) : (
            <div></div>
          )}
          <div className="gallery-subtitle-div">
            <h1 className="gallery-subtitle">Socials</h1>
            <MdOutlineKeyboardArrowDown
              className="gallery-subtitle-button"
              size={40}
              onClick={() => {
                handleOnClick(isOpen3, 3);
              }}
            ></MdOutlineKeyboardArrowDown>
            <div></div>
          </div>
          <div className="gallery-subtitle-divider"></div>
          {isOpen3 ? (
            <ResponsiveMasonry
              columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 4 }}
            >
              <Masonry columnsCount={4} gutter="10px">
                {images.slice(22, 50).map((element, i) => (
                  <div className="gallery-lazy-load-box">
                    <Fade key={i} triggerOnce cascade={0.3}>
                      <img
                        key={i}
                        alt={element.desc}
                        src={element.image}
                        className="gallery-images-child"
                        onClick={() => handleViewImage(element.image, i + 24)}
                      />
                    </Fade>
                  </div>
                ))}
              </Masonry>
            </ResponsiveMasonry>
          ) : (
            <div></div>
          )}
          <div className="gallery-subtitle-div">
            <h1 className="gallery-subtitle">Special Events</h1>
            <MdOutlineKeyboardArrowDown
              className="gallery-subtitle-button"
              size={40}
              onClick={() => {
                handleOnClick(isOpen4, 4);
              }}
            ></MdOutlineKeyboardArrowDown>
            <div></div>
            
          </div>
          <div className="gallery-subtitle-divider"></div>
          {isOpen4 ? (
            <ResponsiveMasonry
              columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 4 }}
            >
              <Masonry columnsCount={4} gutter="10px">
                {images.slice(50, 67).map((element, i) => ( //FIX DEPENDING ON RANGE OF ADDED PICS 
                  <div className="gallery-lazy-load-box">
                    <Fade key={i} triggerOnce cascade={0.3}>
                      <img
                        key={i}
                        alt={element.desc}
                        src={element.image}
                        className="gallery-images-child"
                        onClick={() => handleViewImage(element.image, i + 51)} 
                      />
                    </Fade>
                  </div>
                ))}
              </Masonry>
            </ResponsiveMasonry>
          ) : (
            <div></div>
          )}
        </div>
      </div>
    </>
  );
};

export default Gallery;
