import React, { useState, useEffect } from "react";
import logo from "../assets/gatorhead.png";
import { Fade } from "react-awesome-reveal";
import { EventCard } from "../components/EventCard";
import Carousel, { CarouselItem } from "../components/Carousel";
// import img from "../assets/better-logo.png";
import augustine1 from "../assets/cohortgallery/augustine/1.JPEG";
import augustine2 from "../assets/cohortgallery/augustine/2.JPEG";
import augustine3 from "../assets/cohortgallery/augustine/3.JPEG";
import augustine4 from "../assets/cohortgallery/augustine/4.JPEG";
import augustine5 from "../assets/cohortgallery/augustine/5.JPEG";
import augustine6 from "../assets/cohortgallery/augustine/6.JPEG";
import augustine7 from "../assets/cohortgallery/augustine/7.JPEG";
import augustine9 from "../assets/cohortgallery/augustine/9.JPEG";
import committees2 from "../assets/cohortgallery/committees/2.JPEG";
import committees3 from "../assets/cohortgallery/committees/3.JPEG";
import committees4 from "../assets/cohortgallery/committees/4.JPEG";
import committees5 from "../assets/cohortgallery/committees/5.JPEG";
import committees6 from "../assets/cohortgallery/committees/6.JPEG";
import committees7 from "../assets/cohortgallery/committees/7.JPEG";
import committees8 from "../assets/cohortgallery/committees/8.JPEG";
import intramural1 from "../assets/cohortgallery/intramurals/1.JPEG";
import intramural2 from "../assets/cohortgallery/intramurals/2.JPEG";
import intramural3 from "../assets/cohortgallery/intramurals/3.JPEG";
import intramural4 from "../assets/cohortgallery/intramurals/4.JPEG";
import intramural5 from "../assets/cohortgallery/intramurals/5.JPEG";
import intramural6 from "../assets/cohortgallery/intramurals/6.JPEG";
import intramural7 from "../assets/cohortgallery/intramurals/7.JPEG";
import intramural8 from "../assets/cohortgallery/intramurals/8.JPEG";
import retreat1 from "../assets/cohortgallery/retreat/1.JPEG";
import retreat2 from "../assets/cohortgallery/retreat/2.JPEG";
import retreat3 from "../assets/cohortgallery/retreat/3.JPEG";
import retreat4 from "../assets/cohortgallery/retreat/4.JPEG";
import retreat5 from "../assets/cohortgallery/retreat/5.JPEG";
import retreat6 from "../assets/cohortgallery/retreat/6.JPEG";
import retreat7 from "../assets/cohortgallery/retreat/7.JPEG";
import retreat8 from "../assets/cohortgallery/retreat/8.JPEG";
import reveal1 from "../assets/cohortgallery/reveal/1.JPG";
import reveal2 from "../assets/cohortgallery/reveal/2.JPG";
import reveal3 from "../assets/cohortgallery/reveal/3.JPG";
import reveal4 from "../assets/cohortgallery/reveal/4.JPG";
import reveal5 from "../assets/cohortgallery/reveal/5.JPG";
import reveal6 from "../assets/cohortgallery/reveal/6.JPG";
import reveal7 from "../assets/cohortgallery/reveal/7.JPG";
import reveal8 from "../assets/cohortgallery/reveal/8.JPG";
import reveal9 from "../assets/cohortgallery/reveal/9.JPG";

import EventModal from "../components/EventModal"

import eventList from "../data/events";
import "./Cohort.css";
const Cohort = () => {
  const [size, setSize] = useState(window.innerWidth);
  const [small, setSmall] = useState(false);

  const checkSize = () => {
    setSize(window.innerWidth);
  };

  const [galleryHeight, setGalleryHeight] = useState(700);

  useEffect(() => {
    if (size <= 1000) {
      setGalleryHeight(500);
      if (size <= 800) {
        setGalleryHeight(500);
        if (size <= 700) {
          setGalleryHeight(350);
          if (size <= 550) {
            setGalleryHeight(300);
            if (size <= 450) {
              setGalleryHeight(250);
              if (size <= 400) {
                setGalleryHeight(200);
                if (size <= 325) {
                  setGalleryHeight(150);
                }
                if (size > 325) {
                  setGalleryHeight(200);
                }
              }
              if (size > 400) {
                setGalleryHeight(250);
              }
            }
            if (size > 450) {
              setGalleryHeight(300);
            }
          }
          if (size > 550) {
            setGalleryHeight(350);
          }
        }
        if (size > 700) {
          setGalleryHeight(500);
        }
      }
      if (size > 800) {
        setGalleryHeight(700);
      }
    }
    if (size > 1000) {
      setGalleryHeight(700);
    }
    if (size <= 500) {
      setSmall(true);
    }
    if (size > 500) {
      setSmall(false);
    }
    window.addEventListener("resize", checkSize);
    return () => {
      window.removeEventListener("resize", checkSize);
    };
  }, [size]);

  const augustine = [
    augustine1,
    augustine2,
    augustine3,
    augustine4,
    augustine5,
    augustine6,
    augustine7,
    augustine9,
  ];
  const committees = [
    committees2,
    committees3,
    committees4,
    committees5,
    committees6,
    committees7,
    committees8,
  ];
  const intramural = [
    intramural1,
    intramural2,
    intramural3,
    intramural4,
    intramural5,
    intramural6,
    intramural7,
    intramural8,
  ];
  const retreat = [
    retreat1,
    retreat2,
    retreat3,
    retreat4,
    retreat5,
    retreat6,
    retreat7,
    retreat8,
  ];
  const reveal = [
    reveal1,
    reveal2,
    reveal3,
    reveal4,
    reveal5,
    reveal6,
    reveal7,
    reveal8,
    reveal9,
  ];

  const [openModal, setOpenModal] = useState(false)
  const [selectedEvent, setSelectedEvent] = useState(null);

  const handleOpenModal = (event) => {
    setSelectedEvent(event);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <div className="cohort-div">
      <>  
        <div className="cohort-faux-title"></div>
        <div className="cohort-title-div">
          <div className="cohort-divider"></div>
          <Fade triggerOnce>
            <div className="cohort-title-container">
              <img className="cohort-image" src={logo} alt="" />
              <h1 className="cohort-title">Cohort</h1>
            </div>
          </Fade>
          <div className="cohort-divider"></div>
        </div>

        <div className="cohort-upcoming-div">
          <Fade triggerOnce>
            <h1 className="cohort-upcoming-title">Events</h1>
            <div className="cohort-upcoming-divider"></div>
            <div className="cohort-upcoming-content">
              {eventList.map((event) => {
                return (
                  <EventCard
                    event={event}
                    onOpenModal={handleOpenModal}
                  />
                );
              })}
            </div>
          </Fade>
        </div>
        <EventModal 
            open={openModal} 
            onClose={handleCloseModal}
            event={selectedEvent}
        />
        <div className="cohort-upcoming-div">
          <Fade triggerOnce>
            <h1 className="cohort-calendar-title">Calendar</h1>
            <div className="cohort-upcoming-divider"></div>
            <div className="cohort-calendar">
              <iframe
                src="https://embed.styledcalendar.com/#RvGdeQWINkYqnHutCotg"
                title="Styled Calendar"
                className="styled-calendar-container"
                data-cy="calendar-embed-iframe"
              ></iframe>
              <script
                async
                type="module"
                src="https://embed.styledcalendar.com/assets/parent-window.js"
              ></script>
            </div>
          </Fade>
        </div>

        {/* <div className="cohort-gallery">
          <h1 className="cohort-upcoming-title">Gallery</h1>
          <div className="cohort-upcoming-divider"></div>
          <div className="cohort-gallery-images">
            {small ? (
              <>
                <h1 className="cohort-upcoming-title-sub">Cohort retreat</h1>
                <Carousel autoScroll={3000}>
                  {retreat.map((img) => {
                    return (
                      <CarouselItem
                        height={galleryHeight}
                        image={img}
                      ></CarouselItem>
                    );
                  })}
                </Carousel>
                <h1 className="cohort-upcoming-title-sub">St Augustine Trip</h1>
                <Carousel autoScroll={3000}>
                  {augustine.map((img) => {
                    return (
                      <CarouselItem
                        height={galleryHeight}
                        image={img}
                      ></CarouselItem>
                    );
                  })}
                </Carousel>
                <h1 className="cohort-upcoming-title-sub">Committees</h1>
                <Carousel autoScroll={3000}>
                  {committees.map((img) => {
                    return (
                      <CarouselItem
                        height={galleryHeight}
                        image={img}
                      ></CarouselItem>
                    );
                  })}
                </Carousel>
              </>
            ) : (
              <>
                <h1 className="cohort-upcoming-title-sub">Cohort retreat</h1>
                <Carousel autoScroll={3000}>
                  {retreat.map((img) => {
                    return (
                      <CarouselItem
                        height={galleryHeight}
                        image={img}
                      ></CarouselItem>
                    );
                  })}
                </Carousel>
                <h1 className="cohort-upcoming-title-sub">St Augustine Trip</h1>
                <Carousel autoScroll={3000}>
                  {augustine.map((img) => {
                    return (
                      <CarouselItem
                        height={galleryHeight}
                        image={img}
                      ></CarouselItem>
                    );
                  })}
                </Carousel>
                <h1 className="cohort-upcoming-title-sub">Committees</h1>
                <Carousel autoScroll={3000}>
                  {committees.map((img) => {
                    return (
                      <CarouselItem
                        height={galleryHeight}
                        image={img}
                      ></CarouselItem>
                    );
                  })}
                </Carousel>
                <h1 className="cohort-upcoming-title-sub">Intramurals</h1>
                <Carousel autoScroll={3000}>
                  {intramural.map((img) => {
                    return (
                      <CarouselItem
                        height={galleryHeight}
                        image={img}
                      ></CarouselItem>
                    );
                  })}
                </Carousel>
                <h1 className="cohort-upcoming-title-sub">Mentorship Reveal</h1>
                <Carousel autoScroll={3000}>
                  {reveal.map((img) => {
                    return (
                      <CarouselItem
                        height={galleryHeight}
                        image={img}
                      ></CarouselItem>
                    );
                  })}
                </Carousel>
              </>
            )}
          </div>
        </div> */}
      </>
    </div>
  );
};

export default Cohort;
