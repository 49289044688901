import React, { useState, useEffect } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import pic1 from "../assets/homepage-slider-1.jpeg";
import pic2 from "../assets/homepage-slider-2.jpg";
import pic3 from "../assets/homepage-slider-3.jpg";
import { Link } from "react-router-dom";
import "./HomeSlider.css";

export const CarouselItem = ({ width, height, image }) => {
  return (
    <img
      className="carousel-item"
      src={image}
      alt={"error"}
      style={{ width: width, height: height, opacity: "83%" }}
    ></img>
  );
};

const HomeSlider = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const updateIndex = (newIndex) => {
    if (newIndex < 0) {
      newIndex = 2;
    } else if (newIndex >= 3) {
      newIndex = 0;
    }
    setActiveIndex(newIndex);
  };
  useEffect(() => {
    const interval = setInterval(() => {
      updateIndex(activeIndex + 1);
    }, 5000);

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  });
  return (
    <div>
      <div className="main-div">
        <button
          onClick={() => {
            updateIndex(activeIndex - 1);
          }}
          className="image-button-1"
        >
          <IoIosArrowBack className="arrow"></IoIosArrowBack>
        </button>
        <div className="slider-content">
          <div className="slider-title-div-1">
            <p className="slider-title-text">Being first,</p>
          </div>
          <div className="slider-title-div-2">
            <p className="slider-title-text">together...</p>
          </div>

          <Link to="about">
            <button className="slider-button">Learn more</button>
          </Link>
        </div>
        <button
          onClick={() => {
            updateIndex(activeIndex + 1);
          }}
          className="image-button-2"
        >
          <IoIosArrowForward className="arrow"></IoIosArrowForward>
        </button>
      </div>
      <div className="carousel">
        <div
          className="inner"
          style={{ transform: `translateX(-${activeIndex * 100}%)` }}
        >
          <CarouselItem height={796} width={"100%"} image={pic1}></CarouselItem>
          <CarouselItem height={796} width={"100%"} image={pic2}></CarouselItem>
          <CarouselItem height={796} width={"100%"} image={pic3}></CarouselItem>
        </div>
      </div>
    </div>
  );
};

export default HomeSlider;
